<template>
    <div class="slide_box" :class="{'active' : modelValue}">
        <div class="box_container">
            <button class="close_btn" @click="$emit('update:modelValue', false);"><i class="fas fa-times"></i></button>
            <div class="step_listing">
                <h3 :style="`color: ${selectedPlaybook.title_text_color};`">Course Content</h3>
                <ul>
                    <li v-for="(step, s) in selectedPlaybookSteps" :key="s">
                        <div class="step_detail_card" v-if="!step.is_draft">
                            <div v-if="selectedPlaybook.has_step_thumb" class="thumb pointer" @click="handlePlaybookStepDetail(step)">
                                <img :src="step.thumbnail ? step.thumbnail : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                            </div>
                            <div class="step_info pointer" @click="handlePlaybookStepDetail(step)">
                                <h5>{{ step.title }}</h5>
                                <p>{{ step.thumb_desc && step.thumb_desc.length > 60 ? step.thumb_desc.substr(0, 60) + '...' : step.thumb_desc }}</p>
                            </div>
                            <div class="step_lock" :style="`background: ${selectedPlaybook.step_locked_bg}; color: ${selectedPlaybook.step_locked_text_color};`" v-if="selectedPlaybook.must_be_completed"><i class="fas fa-lock" :style="`border-color: ${selectedPlaybook.step_locked_text_color};`"></i></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: "Step Menu",

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        computed: mapState({
            selectedPlaybook: state => state.playbookModule.selectedPlaybook,
            selectedPlaybookSteps: state => state.playbookModule.playbookSteps,
        }),

        methods:{
            handlePlaybookStepDetail (step) {
                const vm    = this;
                const index = vm.selectedPlaybookSteps.findIndex(s => s.id === step.id);

                vm.playbookStepDetail = true;
                vm.selectedStep       = step
                vm.selectedStepTab    = step.additional_tabs.length ? step.additional_tabs[0] : {};
                vm.nextStep           = vm.selectedPlaybookSteps.length && vm.selectedPlaybookSteps[parseInt(index) + 1] ? vm.selectedPlaybookSteps[parseInt(index) + 1] : [];
                vm.activeStepTab      = vm.selectedStepTab.id;
            },
        }
    }
</script>
<style scoped>
    .slide_box{
        padding: 30px;
        border-radius: 20px 20px 0 0;
        background: #fff;
        position: fixed;
        left: 0;
        bottom: -100%;
        width: 100%;
        max-height: 430px;
        transition: all 0.3s ease-in-out;
        box-shadow: 0 -2px 30px rgba(0, 0, 0, 0.15);
        text-align: left;
        z-index: 3;
    }
    .slide_box.active{
        bottom: 0;
    }
    .step_listing {
        width: 100%;
    }
    .step_listing h3{
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
    }
    .step_detail_card {
        display: flex;
        min-height: 61px;
        margin: 0 0 25px 0;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #e9e9e9;
        background: #fff;
    }
    .step_detail_card .thumb {
        flex: 0 0 106px;
        position: relative;
    }
    .step_listing ul {
        list-style-type: none;
        max-height: 352px;
        overflow-y: scroll;
        margin-top: 20px;
    }
    .step_listing ul::-webkit-scrollbar {
        display: none;
        /* width: 4px; */
    }
    /* .step_listing ul:hover::-webkit-scrollbar-thumb {
        background-color: #c7c7c7;
        border-radius: 2px;
    } */
    .step_detail_card .thumb>img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .step_detail_card .step_info {
        padding: 5px 12px;
        flex: 1 1 auto;
        height: 61px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .step_detail_card .step_info h5 {
        font-size: 11px;
        line-height: 13px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;
    }
    .step_detail_card .step_info p {
        font-size: 9px;
        line-height: 11px;
        color: #5a5a5a;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .close_btn {
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
</style>
